<template>
  <v-app>
    <!-- App Bar -->
    <v-app-bar app color="white" light flat>
      <v-app-bar-nav-icon
        v-if="!sidebarMenu&&!$device.mobile"
        @click.stop="sidebarMenu = !sidebarMenu"
      />
      <MobileNavBar v-if="$device.mobile" />
      <v-spacer />
      <!-- <v-flex xs12 md4 py-3 py-md-0>
        <v-card class="search-box">
          <v-autocomplete
            v-model="talent"
            class="auto-complete content"
            :items="searchedTalents"
            placeholder="Eg. Mick Gordon"
            flat
            tile
            solo
            hide-details
            :hide-no-data="search && search.length ? false : true"
            clearable
            color="black"
            background-color="white"
            prepend-inner-icon="mdi-magnify"
            item-text="name"
            item-value="slug"
            :search-input.sync="search"
            @input="toProfile"
            @click:clear="clearSearch()"
            @focus="clearSearch()"
          >
            <template #item="data" class="mt-4">
              <v-list-item-avatar tile size="60" rounded>
                <v-img
                  :src="
                    data.item.profilePic ? data.item.profilePic : $defaultImg
                  "
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-1">
                  {{ data.item.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  class="text-subtitle-2 light-text text-wrap"
                >
                  {{ data.item.tagLine }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card>
      </v-flex> -->
      <v-spacer />
      <NotificationButton />
      <v-menu offset-y class="flex-column" left>
        <template #activator="{ on, attrs }">
          <v-list>
            <v-list-item class="pa-0">
              <v-list-item-avatar class="rounded ma-0" size="47">
                <v-img
                  v-bind="attrs"
                  class="elevation-6"
                  alt="ProfileImg"
                  :src="profileImage"
                  v-on="on"
                />
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
          <!-- <v-btn icon>
            <v-icon
              dark
              color="black"
            >
              mdi-bell-outline
            </v-icon>
          </v-btn> -->
        </template>
        <v-list>
          <v-list-item to="/talent/dashboard">
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item to="/account/settings">
            <v-list-item-title>{{ $t('account_settings') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- Sidebar -->
    <NavBarList :sidebar.sync="sidebarMenu" />
    <!-- Main Container -->
    <v-container class="py-0 fill-height dashboard-container grey" fluid>
      <v-row>
        <v-col md="12 " class="text-center pa-6 session-card-section">
          <v-btn-toggle
            v-model="toggleBtnText"
            tile
            color="deep-purple accent-3"
            class="availability-toggler"
            group
          >
            <v-btn
              @click="getAppointments()"
              value="appointments"
              class="no-shadow"
            >
              {{ 'Appointments' }}
            </v-btn>

            <v-btn
              @click="getSessionsLists()"
              value="upcomingsessions"
              class="no-shadow"
            >
              {{ 'Upcoming Sessions' }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row v-if="toggleBtnText === 'appointments'" class="fill-height">
        <!--START:: Appointments Lists Section -->
        <v-col
          xl="3"
          lg="4"
          md="4"
          sm="12"
          xs="12"
          class="text-center pa-6 session-card-section"
        >
          <Appointments
            v-if="isShow"
            :sessions="sessions"
            :default-selected-session="selectedSession"
            :card-title="$t('appointments')"
            :no-session-text="$t('no_apt_found')"
            :selected-session.sync="selectedSession"
            :user-type="'guest'"
            :is-disabled="isListDisabled"
          />
        </v-col>
        <!--END:: Appointments Lists Section -->

        <!--START:: Video Streaming Section -->
        <v-col v-if="isShow" xl="9" lg="8" md="8" sm="12" xs="12">
          <VideoStreaming
            :session="selectedSession"
            :user-type="'guest'"
            :is-list-disabled.sync="isListDisabled"
          />
        </v-col>
        <!--END:: Video Streaming Section -->
      </v-row>
      <v-row v-if="toggleBtnText === 'upcomingsessions'" class="fill-height">
        <!--START:: Appointments Lists Section -->
        <v-col
          xl="3"
          lg="4"
          md="4"
          xs="12"
          class="text-left pa-6 grey session-card-section"
        >
          <UpcomingSession
            v-if="isShow"
            :sessions="sessions"
            :default-selected-session="selectedSession"
            :card-title="$t('upc_session')"
            :no-session-text="$t('no_upcmng_session')"
            :selected-session.sync="selectedSession"
            :user-type="'host'"
            :is-disabled="isListDisabled"
          />
        </v-col>
        <!--END:: Appointments Lists Section -->

        <!--START:: Video Streaming Section -->
        <v-col v-if="isShow" xl="9" lg="8" md="8" xs="12" class="pa-0">
          <VideoStreaming
            :session="selectedSession"
            :user-type="'host'"
            :is-list-disabled.sync="isListDisabled"
          />
        </v-col>
        <!--END:: Video Streaming Section -->
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
// graphql
import { GET_BOOKING } from '@/graphql/booking'
import { SEARCH_TALENT } from '@/graphql/talent'

// Components
import Appointments from '@/components/generic/SessionListingCard'
import UpcomingSession from '@/components/generic/SessionListingCard'
import VideoStreaming from '@/components/generic/VideoStreaming'
import NotificationButton from '@/components/generic/NotificationButton.vue'
import MobileNavBar from '@/components/generic/MobileNavBar.vue'
import NavBarList from '@/pages/talent/dashboard/NavBarList.vue'

export default {
  components: {
    Appointments,
    VideoStreaming,
    NotificationButton,
    MobileNavBar,
    NavBarList,
    UpcomingSession,
  },
  data() {
    return {
      sidebarMenu: true,
      toggleMini: false,
      icon: null,
      selectedSession: {},
      selectedSessions: {},
      error: null,
      sessions: [],
      session: [],
      searchedTalents: [],
      isListDisabled: false,
      isShow: false,
      search: null,
      talent: null,
      toggleBtnText: 'appointments',
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'profileImage', 'user', 'jwt']),
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini
    },
  },
  beforeMount() {
    this.icon = '/images/icon/talent/menu.png'
    if(this.$device.mobile) this.sidebarMenu = false;
  },
  watch: {
    search(val) {
      if (!val) {
        this.clearSearch()
        return
      }
      this.searchTalent(val)
    },
  },
  mounted() {
    this.getAppointments()
    //  this.getSessionsLists()
  },
  methods: {
    /**
     * Logout
     */
    async logout() {
      await this.$store.dispatch('logout')
      window.location = '/'
    },
    async searchTalent(keyword) {
      if (keyword) {
        const {
          data: { searchTalent },
        } = await this.$apollo.query({
          query: SEARCH_TALENT,
          variables: {
            searchInput: {
              name: keyword,
              pagination: {
                first: 0,
                skip: 0,
              },
            },
          },
        })

        if (searchTalent.talentList) {
          // Prepare object for searchbox
          this.searchedTalents = searchTalent.talentList.map(t => {
            return {
              name: `${t.user.firstName} ${t.user.lastName}`,
              slug: t.user.username,
              id: t.user.id,
              profilePic: t.user.profileImg ? t.user.profileImg : null,
              tagLine: t.shortIntro,
            }
          })
        } else {
          this.searchedTalents = []
        }
      }
    },

    // Redirect to talent profile page
    toProfile(slug) {
      //  alert(slug)
      this.$nextTick(() => {
        this.search = null
        this.talent = null
      })
      this.searchedTalents = []
      if (slug) this.$router.push(`/talent/profile/${slug}`)
    },

    // Clear Search
    clearSearch() {
      this.search = null
      this.talent = null
      this.searchedTalents = []
    },
    /**
     * Get Appointments Details
     */
    async getAppointments() {
      try {
        const {
          data: { booking },
        } = await this.$apollo.query({
          query: GET_BOOKING,
          fetchPolicy: 'no-cache',
          variables: {
            bookinginput: {
              email: this.user.email,
              accesstoken: this.jwt,
            },
            pagination: {
              first: 0,
              skip: 0,
            },
            tzinfo: this.$defaultTZ,
          },
        })

        if (booking.success) {
          if (booking.bookedList && booking.bookedList.length) {
            this.sessions = []
            booking.bookedList.forEach((session, index) => {
              // if(session.guest.email != this.user.email){
              //  alert("here")
              const date = session.startTime.split('T')[0]
              const sessionIndex = this.sessions.findIndex(
                info => info.date === date
              )
              session.sessionType.charge = this.$options.filters.toFixed(
                session.sessionType.charge,
                2
              )
              if (sessionIndex >= 0) {
                this.sessions[sessionIndex].sessions.push({
                  ...session,
                  index: index,
                })
              } else {
                this.sessions.push({
                  date: date,
                  sessions: [{ ...session, index: index }],
                })
              }
              if (this.sessions.length) {
                console.log(this.sessions[0].sessions)
                this.selectedSession = this.sessions[0].sessions.find(
                  session => session.index == 0
                )
                console.log(this.selectedSession)
              }
            })
          }
        } else {
          this.error = this.$t(booking.error)
        }
        this.isShow = true
      } catch (e) {
        console.error(e) // eslint-disable-line
      }
    },

    async getSessionsLists() {
      //  alert("hello")
      try {
        const {
          data: { booking },
        } = await this.$apollo.query({
          query: GET_BOOKING,
          fetchPolicy: 'no-cache',
          variables: {
            bookinginput: {
              email: this.user.email,
              accesstoken: this.jwt,
            },
            pagination: {
              first: 0,
              skip: 0,
            },
            tzinfo: this.$defaultTZ,
          },
        })

        if (booking.success) {
          this.sessions = []
          console.log(booking)
          if (
            booking.bookedListAppointments &&
            booking.bookedListAppointments.length
          ) {
            booking.bookedListAppointments.forEach((session, index) => {
              if (session.host.email != this.user.email) {
                // alert("here")
                console.log(session)
                const date = session.startTime.split('T')[0]
                const sessionIndex = this.sessions.findIndex(
                  info => info.date === date
                )
                session.sessionType.charge = this.$options.filters.toFixed(
                  session.sessionType.charge,
                  2
                )
                if (sessionIndex >= 0) {
                  this.sessions[sessionIndex].sessions.push({
                    ...session,
                    index: index,
                  })
                } else {
                  this.sessions.push({
                    date: date,
                    sessions: [{ ...session, index: index }],
                  })
                }
                if (this.sessions.length) {
                  console.log(
                    this.sessions[0].sessions.find(
                      session => session.index == 0
                    )
                  )
                  this.selectedSession = this.sessions[0].sessions.find(
                    session => session.index == 0
                  )
                  console.log(this.selectedSession)
                }
              } else {
                this.sessions = []
                this.selectedSession = {}
              }
            })
          }
        } else {
          this.error = this.$t(booking.error)
        }
        this.isShow = true
      } catch (e) {
        console.error(e) // eslint-disable-line
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.session-card-section {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
}
.v-autocomplete__content {
  top: 57px !important;
}
.availability {
  display: flex;
  align-items: center;
}
.availability-toggler {
  min-width: 170px;
  background: #fff;
}
.availability-toggler .v-btn--active::before {
  background: #ffb421 !important;
  color: #fff !important;
  opacity: 1;
  border-radius: 5px;
}
.availability-toggler .v-btn--active {
  color: #fff !important;
}
.session-card-section {
  border: none !important;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgb(0 0 0 / 0%) !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active.v-autocomplete__content {
  width: 404px;
  top: 65px !important;
  border-radius: 4px !important;
}
@media only screen and (max-width: 600px) {
  .col.col-sm-12.pa-6.session-card-section button {
    font-size: 13px;
  }
}
@media only screen and (max-width: 959px) {
  nav.dektop-view.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--floating.v-navigation-drawer--open.theme--light {
    display: none;
  }
  .mobile-view {
    display: block;
    position: absolute;
    left: -42px;
  }
  // #app
  //   .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  //   min-width: 90% !important;
  //   max-width: 94% !important;
  // }
  .v-main {
    padding: 56px 0px 0px 0px !important;
  }
}
@media only screen and (max-width: 768px) {
  main.v-main {
    padding-left: 0px !important;
  }
  nav.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--floating.v-navigation-drawer--mini-variant.v-navigation-drawer--open.theme--light {
    display: none;
  }
  .v-image.v-responsive.elevation-6.theme--light {
    display: block;
  }
}
</style>
